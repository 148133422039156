/* eslint-disable global-require */
import React from 'react';
import PropTypes from 'prop-types';
import Footer from 'cccisd-footer';
import { isIE } from 'js/utils.js';
import classnames from 'classnames';
import Header from 'js/components/Header';
import style from './style.css';

export const images = [
    require('./CatConfused.svg'),
    require('./CatYay.svg'),
    require('./CatHandOnHip.svg'),
    require('./BuckConfused.svg'),
    require('./BuckProud.svg'),
];

export default class FixedLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        showMenu: PropTypes.bool,
        inBox: PropTypes.bool,
        logoInBox: PropTypes.bool,
        showSaveButton: PropTypes.bool,
        showHelpLink: PropTypes.bool,
        showPolicyLinks: PropTypes.bool,
        characters: PropTypes.array,
        isBackgroundOpacity: PropTypes.bool,
        saveButtonUrl: PropTypes.string,
    };

    static defaultProps = {
        characters: [],
        isBackgroundOpacity: false,
    };

    render() {
        return (
            <div
                className={classnames(style.body, {
                    [style.opacity]: this.props.isBackgroundOpacity,
                })}
            >
                {this.props.characters.map(character => (
                    <div key={character} className={`bgca-character ${style.character} ${style[character]}`} />
                ))}
                <div className={style.content}>
                    <Header
                        containerClassName="container"
                        showSaveButton={this.props.showSaveButton}
                        saveButtonUrl={this.props.saveButtonUrl}
                    />
                    <div className="container">
                        <div className={classnames({ [style.box]: this.props.inBox })}>
                            {this.props.inBox && this.props.logoInBox && (
                                <div>
                                    <div className={style.logo} />
                                    <div className={style.title1}>Boys & Girls Clubs</div>
                                    <div className={style.title2}>of America</div>
                                </div>
                            )}
                            {this.props.children}
                        </div>
                    </div>
                </div>
                {!isIE() && (
                    <div className={style.footer}>
                        <Footer
                            className="container"
                            showPolicyLinks={this.props.showPolicyLinks}
                            showContactUsLink={false}
                        />
                    </div>
                )}
            </div>
        );
    }
}
