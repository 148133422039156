import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'cccisd-modal';
import ClubForm from 'js/components/ClubForm';
import IconPencil from 'cccisd-icons/pencil';
import axios from 'cccisd-axios';
import { connect } from 'react-redux';
import Tooltip from 'cccisd-tooltip';
import _mapKeys from 'lodash/mapKeys';

const Boilerplate = window.cccisd.boilerplate;

class Component extends React.Component {
    static propTypes = {
        row: PropTypes.object,
        loadData: PropTypes.func,
        modules: PropTypes.array,
    };

    onSubmit = async values => {
        const result = await axios.put(
            Boilerplate.route('api.nexus.group.update', { group: this.props.row['group.groupId'] }),
            {
                ...values,
                label: values.clubName,
                parent: +values.groupId,
            }
        );

        if (result.data.errors) {
            const errors = _mapKeys(result.data.errors, (value, key) => {
                if (key === 'Club ID') {
                    return 'clubId';
                }
                if (key === 'label') {
                    return 'clubName';
                }

                return key;
            });

            throw errors;
        }
    };

    getInitialValues = () => {
        const { row } = this.props;

        return {
            groupId: row['ancestorGroups.organization.group.groupId'],
            clubId: row['fields.clubId'],
            clubName: row['group.label'],
            surveyMethod: row['fields.surveyMethod'],
            paperEligible: row['fields.paperEligible'],
            participatingThisYear: row['fields.participatingThisYear'],
            consentForm: row['fields.consentForm'],
            surveyMethodLastYear: row['fields.surveyMethodLastYear'],
            youthResponsesCollectedLastYear: row['fields.youthResponsesCollectedLastYear'],
            teenResponsesCollectedLastYear: row['fields.teenResponsesCollectedLastYear'],
            paperOrderEnglishYouthLastYear: row['fields.paperOrderEnglishYouthLastYear'],
            paperOrderSpanishYouthLastYear: row['fields.paperOrderSpanishYouthLastYear'],
            paperOrderEnglishTeenLastYear: row['fields.paperOrderEnglishTeenLastYear'],
            paperOrderSpanishTeenLastYear: row['fields.paperOrderSpanishTeenLastYear'],
            paperReturnEnglishYouthLastYear: row['fields.paperReturnEnglishYouthLastYear'],
            paperReturnSpanishYouthLastYear: row['fields.paperReturnSpanishYouthLastYear'],
            paperReturnEnglishTeenLastYear: row['fields.paperReturnEnglishTeenLastYear'],
            paperReturnSpanishTeenLastYear: row['fields.paperReturnSpanishTeenLastYear'],
            paperOrderEnglishYouth: row['fields.paperOrderEnglishYouth'],
            paperOrderSpanishYouth: row['fields.paperOrderSpanishYouth'],
            paperOrderEnglishTeen: row['fields.paperOrderEnglishTeen'],
            paperOrderSpanishTeen: row['fields.paperOrderSpanishTeen'],
            maxYouth: row['fields.maxYouth'],
            maxTeen: row['fields.maxTeen'],
            maxOptionalModulesAllowed: row['fields.maxOptionalModulesAllowed'],
            skipRiskQuestionsBaseSurvey: row['fields.skipRiskQuestionsBaseSurvey'],
            riskBehaviorModuleAllowed: row['fields.riskBehaviorModuleAllowed'],
            riskBehaviorModuleRequired: row['fields.riskBehaviorModuleRequired'],
            riskBehaviorModuleSelected:
                row['fields.riskBehaviorModuleRequired'] || row['fields.riskBehaviorModuleSelected'],
            riskBehaviorModuleSelectedLastYr: row['fields.riskBehaviorModuleSelectedLastYr'],
            sedModuleAllowed: row['fields.sedModuleAllowed'],
            sedModuleRequired: row['fields.sedModuleRequired'],
            sedModuleSelected: row['fields.sedModuleRequired'] || row['fields.sedModuleSelected'],
            sedModuleSelectedLastYear: row['fields.sedModuleSelectedLastYear'],
            artsModuleAllowed: row['fields.artsModuleAllowed'],
            artsModuleRequired: row['fields.artsModuleRequired'],
            artsModuleSelected: row['fields.artsModuleRequired'] || row['fields.artsModuleSelected'],
            artsModuleSelectedLastYear: row['fields.artsModuleSelectedLastYear'],
            stemModuleAllowed: row['fields.stemModuleAllowed'],
            stemModuleRequired: row['fields.stemModuleRequired'],
            stemModuleSelected: row['fields.stemModuleRequired'] || row['fields.stemModuleSelected'],
            stemModuleSelectedLastYear: row['fields.stemModuleSelectedLastYear'],
            bullyingModuleAllowed: row['fields.bullyingModuleAllowed'],
            bullyingModuleRequired: row['fields.bullyingModuleRequired'],
            bullyingModuleSelected: row['fields.bullyingModuleRequired'] || row['fields.bullyingModuleSelected'],
            bullyingModuleSelectedLastYear: row['fields.bullyingModuleSelectedLastYear'],
            collegeReadyModuleAllowed: row['fields.collegeReadyModuleAllowed'],
            collegeReadyModuleRequired: row['fields.collegeReadyModuleRequired'],
            collegeReadyModuleSelected:
                row['fields.collegeReadyModuleRequired'] || row['fields.collegeReadyModuleSelected'],
            collegeReadyModuleSelectedLastYr: row['fields.collegeReadyModuleSelectedLastYr'],
            workforceReadyModuleAllowed: row['fields.workforceReadyModuleAllowed'],
            workforceReadyModuleRequired: row['fields.workforceReadyModuleRequired'],
            workforceReadyModuleSelected:
                row['fields.workforceReadyModuleRequired'] || row['fields.workforceReadyModuleSelected'],
            workforceReadyModuleSelectedLast: row['fields.workforceReadyModuleSelectedLast'],
        };
    };

    render() {
        // disable changing if any students have taken the survey at the club
        const disableUpdating = this.props.row['descendantRoles.studentList'].length > 0;

        return (
            <Modal
                trigger={
                    <Tooltip title="Edit">
                        <button type="button" className="btn btn-xs btn-success">
                            <IconPencil />
                        </button>
                    </Tooltip>
                }
                title="Edit Club"
            >
                {disableUpdating ? (
                    <div className="alert alert-danger" style={{ margin: 0 }}>
                        Surveys at this Club have started.
                        <br />
                        Club is locked for updating.
                    </div>
                ) : (
                    <ClubForm
                        initialValues={this.getInitialValues()}
                        loadData={this.props.loadData}
                        onSubmit={this.onSubmit}
                    />
                )}
            </Modal>
        );
    }
}

const mapStateToProps = (state, props) => ({
    modules: state.app.admin.modules,
});

export default connect(mapStateToProps)(Component);
