import React from 'react';
import PropTypes from 'prop-types';
import Form from './form.js';
import { graphql } from 'cccisd-apollo';
import query from './getOrganizations.graphql';
import Loader from 'cccisd-loader';
import { connect } from 'react-redux';

class ClubForm extends React.Component {
    static propTypes = {
        initialValues: PropTypes.object,
        data: PropTypes.object,
        loadData: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
        onSubmit: PropTypes.func,
        modules: PropTypes.array,
    };

    onSubmit = async values => {
        await this.props.onSubmit(values);
        await this.props.loadData();
        this.props.closeModal();
    };

    getInitialValues = () => {
        return {
            participatingThisYear: false,
            paperEligible: false,
            surveyMethod: 'online',
            consentForm: 'active',
            surveyMethodLastYear: 'online',
            youthResponsesCollectedLastYear: 0,
            teenResponsesCollectedLastYear: 0,
            paperReturnEnglishYouthLastYear: 0,
            paperReturnSpanishYouthLastYear: 0,
            paperReturnEnglishTeenLastYear: 0,
            paperReturnSpanishTeenLastYear: 0,
            paperOrderEnglishYouthLastYear: 0,
            paperOrderSpanishYouthLastYear: 0,
            paperOrderEnglishTeenLastYear: 0,
            paperOrderSpanishTeenLastYear: 0,
            paperOrderEnglishYouth: 0,
            paperOrderSpanishYouth: 0,
            paperOrderEnglishTeen: 0,
            paperOrderSpanishTeen: 0,
            maxYouth: 0,
            maxTeen: 0,
            maxOptionalModulesAllowed: 2,
            skipRiskQuestionsBaseSurvey: 0,
            riskBehaviorModuleAllowed: true,
            riskBehaviorModuleRequired: false,
            riskBehaviorModuleSelected: false,
            riskBehaviorModuleSelectedLastYr: false,
            sedModuleAllowed: true,
            sedModuleRequired: false,
            sedModuleSelected: false,
            sedModuleSelectedLastYear: false,
            artsModuleAllowed: true,
            artsModuleRequired: false,
            artsModuleSelected: false,
            artsModuleSelectedLastYear: false,
            stemModuleAllowed: true,
            stemModuleRequired: false,
            stemModuleSelected: false,
            stemModuleSelectedLastYear: false,
            bullyingModuleAllowed: true,
            bullyingModuleRequired: false,
            bullyingModuleSelected: false,
            bullyingModuleSelectedLastYear: false,
            collegeReadyModuleAllowed: true,
            collegeReadyModuleRequired: false,
            collegeReadyModuleSelected: false,
            collegeReadyModuleSelectedLastYr: false,
            workforceReadyModuleAllowed: true,
            workforceReadyModuleRequired: false,
            workforceReadyModuleSelected: false,
            workforceReadyModuleSelectedLast: false,
            ...this.props.initialValues,
        };
    };

    getOrganizations = () =>
        this.props.data.groups.organizationList.map(item => ({
            orgId: item.fields.orgId,
            groupId: item.group.groupId,
            label: item.group.label,
        }));

    render() {
        const { loading } = this.props.data;

        if (loading) {
            return <Loader loading type="inline" />;
        }

        return (
            <Form
                onSubmit={this.onSubmit}
                initialValues={this.getInitialValues()}
                organizations={this.getOrganizations()}
                modules={this.props.modules}
            />
        );
    }
}

const mapStateToProps = (state, props) => ({
    modules: state.app.admin.modules,
});

export default graphql(query)(connect(mapStateToProps)(ClubForm));
