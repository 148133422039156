import React from 'react';
import { makeDownloadButton } from 'cccisd-tabletop';
import clubsQuery from './clubsQuery.graphql';
import style from './style.css';

export default class DownloadAllOrgClubInfo extends React.Component {
    getDownloadColumns = () => {
        return [
            { label: 'Organization_Id', name: 'ancestorGroups.organization.fields.orgId' },
            {
                label: 'Organization_Registration_Complete',
                name: 'ancestorGroups.organization.fields.registrationComplete',
            },
            {
                label: 'Org_Primary_Contact',
                name: 'ancestorGroups.organization.descendantRoles.orgAdmin.user.fullNameWithEmail',
            },
            { label: 'Club_Id', name: 'fields.clubId' },
            { label: 'Club_Name', name: 'group.label' },
            { label: 'Club_Primary_Contact', name: 'descendantRoles.clubContact.user.fullNameWithEmail' },

            { label: 'Survey_Method', name: 'fields.surveyMethod' },
            { label: 'Permission_Form', name: 'fields.consentForm' },
            { label: 'Participating_This_Year', name: 'fields.participatingThisYear' },
            { label: 'Eligible_for_paper', name: 'fields.paperEligible' },
            {
                label: 'Paper_Survey_Returned_English_Youth',
                name: 'fields.paperReturnEnglishYouthLastYear',
            },
            {
                label: 'Paper_Survey_Returned_Spanish_Youth',
                name: 'fields.paperReturnSpanishYouthLastYear',
            },
            {
                label: 'Paper_Survey_Returned_English_Teen',
                name: 'fields.paperReturnEnglishTeenLastYear',
            },
            {
                label: 'Paper_Survey_Returned_Spanish_Teen',
                name: 'fields.paperReturnSpanishTeenLastYear',
            },
            {
                label: 'Paper_Survey_Order_English_Youth',
                name: 'fields.paperOrderEnglishYouth',
            },
            {
                label: 'Paper_Survey_Order_Spanish_Youth',
                name: 'fields.paperOrderSpanishYouth',
            },
            {
                label: 'Paper_Survey_Order_English_Teen',
                name: 'fields.paperOrderEnglishTeen',
            },
            {
                label: 'Paper_Survey_Order_Spanish_Teen',
                name: 'fields.paperOrderSpanishTeen',
            },
            { label: 'Max_Youth', name: 'fields.maxYouth' },
            { label: 'Max_Teen', name: 'fields.maxTeen' },
            { label: 'Max_Optional_Modules_Allowed', name: 'fields.maxOptionalModulesAllowed' },
            {
                label: 'Skip_Risk_Behavior_Questions_Base_Survey',
                name: 'fields.skipRiskQuestionsBaseSurvey',
            },
            { label: 'Risk_Behavior_Module_Allowed', name: 'fields.riskBehaviorModuleAllowed' },
            { label: 'Risk_Behavior_Module_Required', name: 'fields.riskBehaviorModuleRequired' },
            {
                label: 'Risk_Behavior_Module_Selected',
                name: 'fields.riskBehaviorModuleSelected',
            },
            { label: 'SED_Module_Allowed', name: 'fields.sedModuleAllowed' },
            { label: 'SED_Module_Required', name: 'fields.sedModuleRequired' },
            { label: 'SED_Module_Selected', name: 'fields.sedModuleSelected' },
            { label: 'Arts_Module_Allowed', name: 'fields.artsModuleAllowed' },
            { label: 'Arts_Module_Required', name: 'fields.artsModuleRequired' },
            { label: 'Arts_Module_Selected', name: 'fields.artsModuleSelected' },
            { label: 'STEM_Module_Allowed', name: 'fields.stemModuleAllowed' },
            { label: 'STEM_Module_Required', name: 'fields.stemModuleRequired' },
            { label: 'STEM_Module_Selected', name: 'fields.stemModuleSelected' },
            { label: 'Bullying_Module_Allowed', name: 'fields.bullyingModuleAllowed' },
            { label: 'Bullying_Module_Required', name: 'fields.bullyingModuleRequired' },
            { label: 'Bullying_Module_Selected', name: 'fields.bullyingModuleSelected' },
            {
                label: 'College_Readiness_Module_Allowed',
                name: 'fields.collegeReadyModuleAllowed',
            },
            {
                label: 'College_Readiness_Module_Required',
                name: 'fields.collegeReadyModuleRequired',
            },
            {
                label: 'College_Readiness_Module_Selected',
                name: 'fields.collegeReadyModuleSelected',
            },
            {
                label: 'Workforce_Readiness_Module_Allowed',
                name: 'fields.workforceReadyModuleAllowed',
            },
            {
                label: 'Workforce_Readiness_Module_Required',
                name: 'fields.workforceReadyModuleRequired',
            },
            {
                label: 'Workforce_Readiness_Module_Selected',
                name: 'fields.workforceReadyModuleSelected',
            },
        ];
    };

    renderDownloadButton = () => {
        const data = {
            tableProps: {
                columns: this.getDownloadColumns(),
                csvFilename: 'clubs.csv',
                query: clubsQuery,
            },
            buttonName: 'Download Info On All Clubs',
            buttonClass: 'btn btn-primary',
        };

        const button = makeDownloadButton(data);
        const getActualVariables = () => {
            return {
                graphqlVariables: {
                    orderBy: [{ field: 'ancestorGroups.organization.fields.orgId', direction: 'ASC' }],
                },
            };
        };

        const Button = button({ getActualVariables });
        return Button;
    };

    render() {
        return <span className={style.btn}>{this.renderDownloadButton()}</span>;
    }
}
