import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tooltip from 'cccisd-tooltip';
import IconQuestion from 'cccisd-icons/question4';
import _get from 'lodash/get';
import style from './style.css';

const BASE_SURVEY_TIME_TIME = 14 * 60 + 13; // 14:13
const WARNING_TAKE_TIME = 20;
const DANGER_TAKE_TIME = 22;

const getSelectedModulesTakeTime = (allModules, selectedModules) => {
    return allModules.filter(item => selectedModules.includes(item.code)).reduce((sum, item) => sum + item.takeTime, 0);
};
const getTotalTakeTime = (allModules, selectedModules) => {
    return BASE_SURVEY_TIME_TIME + getSelectedModulesTakeTime(allModules, selectedModules);
};
const formatTakeTime = time => {
    return `${Math.floor(time / 60)}:${`${time % 60}`.padStart(2, '0')} mins`;
};

export const fields = ['allModules', 'selectedModules[]'];

export const validate = (values, props) => {
    const errors = {};
    const { modules, club } = props;

    const allSelectedModulesRequired = modules
        .filter(item => club.modules[item.code]?.allowed && values.selectedModules.includes(item.code))
        .every(item => club.modules[item.code]?.required);

    const maxModules = _get(props, 'club.maxOptionalModulesAllowed', 0);
    if (values.selectedModules.length > maxModules) {
        errors.allModules = `You are allowed to select only ${maxModules} modules`;
    }

    if (!allSelectedModulesRequired) {
        const totalTakeTimeInMinutes = getTotalTakeTime(modules, values.selectedModules) / 60;
        if (totalTakeTimeInMinutes > DANGER_TAKE_TIME) {
            errors.selectedModules = `Take time is more than ${DANGER_TAKE_TIME} minutes`;
        }
    }

    return errors;
};

export default class Form extends React.Component {
    static propTypes = {
        fields: PropTypes.object.isRequired,
        club: PropTypes.object.isRequired,
        modules: PropTypes.array.isRequired,
        errors: PropTypes.object.isRequired,
        values: PropTypes.object.isRequired,
    };

    componentDidUpdate() {
        this.selectOnlyAllowedModules();
    }

    selectOnlyAllowedModules = () => {
        const { selectedModules } = this.props.values;
        const allowedModules = this.getAllowedModules().map(item => item.code);

        selectedModules.forEach((item, index) => {
            if (!allowedModules.includes(item)) {
                this.props.fields.selectedModules.removeField(index);
            }
        });
    };

    getAllowedModules = () => {
        const { club, modules } = this.props;

        return modules.filter(item => club.modules[item.code] && club.modules[item.code].allowed);
    };

    onSelectModule = e => {
        const { selectedModules } = this.props.fields;
        const code = e.target.value;
        const index = selectedModules.findIndex(item => item.value === code);
        if (index !== -1) {
            selectedModules.removeField(index);
        } else {
            selectedModules.addField(code);
        }
    };

    render() {
        const {
            values: { selectedModules },
            club,
            errors,
        } = this.props;

        const allowedModules = this.getAllowedModules();
        const haveAllowedModules = allowedModules.length > 0;
        const totalTakeTime = getTotalTakeTime(this.props.modules, selectedModules);
        const selectedModulesTakeTime = getSelectedModulesTakeTime(this.props.modules, selectedModules);
        const totalTakeTimeInMinutes = totalTakeTime / 60;
        const allSelectedModulesRequired = allowedModules.every(
            item => !selectedModules.includes(item.code) || club.modules[item.code]?.required
        );

        return (
            <div className="form-group">
                <div className={classnames('form-group', { 'has-error': errors.allModules })}>
                    <label>Select optional modules for the Club:</label>
                    {!haveAllowedModules && (
                        <div className="alert alert-warning">
                            There are no optional modules for this club. If you want to add optional modules, please
                            contact support.
                        </div>
                    )}
                    {errors.allModules && (
                        <div className="help-block" style={{ marginTop: 0 }}>
                            {errors.allModules}
                        </div>
                    )}
                    {allowedModules.map(item => (
                        <div key={item.code}>
                            <div className={style.module}>
                                <div className={style.lastYear}>{club.modules[item.code].selectedLastYear && '*'}</div>
                                <div className="checkbox" style={{ margin: '0' }}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            disabled={club.modules[item.code].required}
                                            checked={selectedModules.includes(item.code)}
                                            value={item.code}
                                            onChange={this.onSelectModule}
                                            className={classnames({
                                                [style.required]: club.modules[item.code].required,
                                            })}
                                        />{' '}
                                        {item.title}
                                        {item.teenOnly && <span className={style.teenOnly}>(Teen only)</span>}
                                    </label>
                                </div>
                                <div>
                                    {item.description && (
                                        <Tooltip title={<div style={{ textAlign: 'left' }}>{item.description}</div>}>
                                            <IconQuestion spaceLeft spaceRight />
                                        </Tooltip>
                                    )}
                                </div>
                            </div>
                            <div className={style.takeTime}>Survey Time: {formatTakeTime(item.takeTime)}</div>
                        </div>
                    ))}
                </div>
                {haveAllowedModules && (
                    <div className={style.moduleComment}>
                        <span className={style.lastYear}>*</span> Indicates module selections for previous year
                    </div>
                )}

                <div className={style.totalTakeTime}>
                    Required Base Survey (<b>{formatTakeTime(BASE_SURVEY_TIME_TIME)}</b>) + Optional Modules (
                    <b>{formatTakeTime(selectedModulesTakeTime)}</b>) = Total Survey Time (
                    <b>{formatTakeTime(totalTakeTime)}</b>)
                    {!allSelectedModulesRequired &&
                        totalTakeTimeInMinutes > WARNING_TAKE_TIME &&
                        totalTakeTimeInMinutes <= DANGER_TAKE_TIME && (
                            <div className={'alert alert-warning ' + style.totalTakeTimeWarning}>
                                Caution! Your survey is getting a little long. Do not exceed {DANGER_TAKE_TIME} minutes.
                            </div>
                        )}
                    {!allSelectedModulesRequired && totalTakeTimeInMinutes > DANGER_TAKE_TIME && (
                        <div className={'alert alert-danger ' + style.totalTakeTimeWarning}>
                            Warning! Your total survey time is too long. Please deselect one or more optional modules.
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
