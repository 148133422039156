import React from 'react';
import { ProducerPlayer } from 'cccisd-video-player';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

const options = {
    autoplay: false,
    loop: false,
    controls: true,
    volume: 1,
};

const Tutorial = props => {
    return (
        <div>
            <p>
                Watch the Registration Walk-through Video or download the{' '}
                <a
                    href={Boilerplate.url('/api/resources/file/public/2024_NYOI%20RegistrationGuide.pdf')}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-nowrap"
                >
                    Registration Walk-through PDF
                </a>
                .
            </p>

            <ProducerPlayer
                id="17056"
                autoplay={options.autoplay}
                loop={options.loop}
                controls={options.controls}
                volume={options.volume}
            />
        </div>
    );
};

Tutorial.propTypes = {};

Tutorial.defaultProps = {};

export default Tutorial;
