import { handleActions, createAction } from 'redux-actions';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _mapKeys from 'lodash/mapKeys';
import { client } from 'cccisd-apollo';
import axios from 'cccisd-axios';
import queryGetInitialStateForOrgAdmin from './getInitialStateForOrgAdmin.graphql';
import queryGetInitialStateForClubContact from './getInitialStateForClubContact.graphql';

const actingUser = _get(window, 'cccisd.fortress.user.acting');
const Boilerplate = window.cccisd.boilerplate;
let addressChangeHandle;
const addressChangeTimeout = 1000; // Wait this time before saving the address

const adjustSettings = settings => {
    const fieldsMap = {
        address1: 'orgShippingAddress1',
        address2: 'orgShippingAddress2',
        city: 'orgShippingCity',
        state: 'orgShippingState',
        zip: 'orgShippingZipCode',
        firstName: 'first_name',
        lastName: 'last_name',
    };

    return _mapKeys(settings, (value, key) => fieldsMap[key] || key);
};

export const persistedKeys = ['activeItem', 'paneShowStatus'];

// Initial state
export const initialState = {
    currentContactId: _get(actingUser, 'user.current_pawn_id'),
    actingUserName: _get(actingUser, 'user.full_name'),
    actingUserId: _get(actingUser, 'user.id'),
    actingUserRole: _get(actingUser, 'role.handle'),
    navItems: [
        { code: 'dashboard', title: 'Dashboard', role: ['orgAdmin'] },
        { code: 'clubDashboard', title: 'Survey Readiness', role: ['clubContact'] },
        { code: 'readiness', title: 'Survey Readiness', role: ['orgAdmin'] },
        { code: 'progress', title: 'Member Survey Progress', role: ['orgAdmin', 'clubContact'] },
        { code: 'resource', title: 'Resource Center', role: ['orgAdmin', 'clubContact'] },
        { code: 'shipping', title: 'Shipping Details', role: ['orgAdmin', 'clubContact'] },
        { code: 'manage', title: 'Manage', role: ['orgAdmin'] },
    ],
    paneShowStatus: {
        onlineAccess: true,
        paperAccess: true,
    },
    activeItem: 'dashboard',
    modules: [
        {
            code: 'riskBehavior',
            title: 'Risk Behavior',
            description:
                'The Youth Risk Behavior Survey module asks questions to help Clubs better understand the positive impact that Club participation has on members. This module asks a selection of questions from the Youth Risk Behavior Surveillance (YRBS), a national survey administered by the Centers for Disease Control and Prevention (CDC) that monitors health-risk behaviors among youth and young adults.',
            consentForms: ['passive', 'active'],
            takeTime: 156, // 2:36
            teenOnly: true,
        },
        {
            code: 'sed',
            title: 'Social and Emotional Development',
            description:
                'The SED module asks questions to help Clubs better understand members’ competencies and behaviors in key intrapersonal and interpersonal skills that help promote positive social engagement and set the stage for later success.',
            consentForms: ['passive', 'active'],
            takeTime: 154, // 2:34
        },
        {
            code: 'arts',
            title: 'The Arts',
            description:
                'This Arts module asks questions to help Clubs better understand members’ skills and attitudes for the Arts; along with their interest in, and support for, a career in the Arts.',
            consentForms: ['passive', 'active'],
            takeTime: 88, // 1:28
        },
        {
            code: 'stem',
            title: 'STEM',
            description:
                'The STEM module asks questions to help Clubs better understand members’ interest in, and level of preparation for, STEM careers and to inform new program development.',
            consentForms: ['passive', 'active'],
            takeTime: 117, // 1:57
        },
        {
            code: 'bullying',
            title: 'Bullying',
            description:
                'The Bullying module asks questions to help Clubs determine the need for anti-bullying resources and understand whether members have been bullied at school, at their local Club, or electronically over the past year.',
            consentForms: ['passive', 'active'],
            takeTime: 86, // 1:26
        },
        {
            code: 'collegeReady',
            title: 'College Readiness',
            description:
                'The College Readiness module asks questions to help Clubs better understand members’ interest in, and level of preparation for, continuing their education after high school.  Please note, these items were previously included in the Base Survey.',
            consentForms: ['passive', 'active'],
            takeTime: 120, // 2:00
            teenOnly: true,
        },
        {
            code: 'workforceReady',
            title: 'Workforce Readiness',
            description:
                'The Workforce Readiness module asks questions to help Clubs better understand members’ skills related to the workplace and their experiences with work-based learning and (e.g., service-learning, job shadowing, first jobs, in-Club internships, external internships, pre-apprenticeships).',
            consentForms: ['passive', 'active'],
            takeTime: 106, // 1:46
            teenOnly: true,
        },
    ],
    clubs: [],
    contacts: [],
    maxOrganizationContacts: 3,
    memberStat: {},
};

// Actions
const SET_INITIAL_STATE_FOR_ORG_ADMIN = 'bgca/admin/SET_INITIAL_STATE_FOR_ORG_ADMIN';
const SET_INITIAL_STATE_FOR_CLUB_CONTACT = 'bgca/admin/SET_INITIAL_STATE_FOR_CLUB_CONTACT';
const SET_ACTIVE_ITEM = 'bgca/admin/SET_ACTIVE_ITEM';
const CHANGE_CLUB_SETTINGS = 'bgca/admin/CHANGE_CLUB_SETTINGS';
const SET_ADDRESS = 'bgca/admin/SET_ADDRESS';
const UPDATE_CONTACT = 'bgca/admin/UPDATE_CONTACT';
const TOGGLE_PANE = 'bgca/admin/TOGGLE_PANE';
const COMPLETE_REGISTRATION = 'bgca/admin/COMPLETE_REGISTRATION';
const HIDE_WELCOME = 'bgca/admin/HIDE_WELCOME';
const SET_MEMBER_STAT = 'bgca/admin/SET_MEMBER_STAT';

// Action Creators
export const setInitialStateForOrgAdmin = createAction(SET_INITIAL_STATE_FOR_ORG_ADMIN);
export const setInitialStateForClubContact = createAction(SET_INITIAL_STATE_FOR_CLUB_CONTACT);
export const setActiveItem = createAction(SET_ACTIVE_ITEM);
export const changeClubSettingsWithoutSaving = createAction(CHANGE_CLUB_SETTINGS);
export const setAddressWithoutSaving = createAction(SET_ADDRESS);
export const updateContactWithoutSaving = createAction(UPDATE_CONTACT);
export const togglePane = createAction(TOGGLE_PANE);
export const completeRegistrationWithoutSaving = createAction(COMPLETE_REGISTRATION);
export const hideWelcomeWithoutSaving = createAction(HIDE_WELCOME);
export const setMemberStat = createAction(SET_MEMBER_STAT);

export const loadInitialState = (force = false) => {
    return async (dispatch, getState) => {
        const { organizationId, actingUserRole, actingUserId } = getState().app.admin;

        // Don't reload the state twice
        if (!organizationId || force) {
            if (actingUserRole === 'orgAdmin') {
                const response1 = await client.query({
                    query: queryGetInitialStateForOrgAdmin,
                    fetchPolicy: 'network-only',
                    errorPolicy: 'all',
                });
                dispatch(setInitialStateForOrgAdmin(response1.data));

                const response2 = await axios.get(Boilerplate.route('org.survey.counts'));
                dispatch(
                    setMemberStat({
                        clubs: response2.data.orgs[0].clubs,
                        orgStaffSurveyCount: response2.data.orgs[0].staffSurveyCount,
                    })
                );
            }
            if (actingUserRole === 'clubContact') {
                const response1 = await client.query({
                    query: queryGetInitialStateForClubContact,
                    variables: {
                        userId: actingUserId,
                    },
                    fetchPolicy: 'network-only',
                    errorPolicy: 'all',
                });
                dispatch(setInitialStateForClubContact(response1.data));

                const response2 = await axios.get(Boilerplate.route('club.survey.counts'));
                dispatch(setMemberStat({ clubs: response2.data.clubs }));
            }
        }
    };
};

export const changeClubSettings = ({ groupId, settings }) => {
    return async (dispatch, getState) => {
        const state = getState().app.admin;

        const adjustedSettings = adjustSettings(settings);
        if (settings.selectedModules) {
            state.modules.forEach(item => {
                adjustedSettings[`${item.code}ModuleSelected`] = settings.selectedModules.includes(item.code);
            });
        }

        const result = await axios.put(
            Boilerplate.route('api.nexus.group.update', { group: groupId }),
            adjustedSettings
        );

        if (result.data.errors) {
            throw result.data.errors;
        }

        if (settings.contactUserId) {
            const clubContact = state.contacts.find(
                item =>
                    item.userId === settings.contactUserId && item.role === 'clubContact' && item.groupId === groupId
            );

            if (clubContact) {
                await dispatch(
                    updateContact({
                        id: clubContact.id,
                        settings: { primaryContact: true },
                        reload: false,
                    })
                );
            } else {
                await dispatch(
                    addClubContactForUser({
                        userId: settings.contactUserId,
                        groupId,
                        reload: false,
                    })
                );
            }
        }

        await dispatch(loadInitialState(true));
    };
};

export const hideWelcome = () => {
    return async (dispatch, getState) => {
        const { organizationId } = getState().app.admin;

        dispatch(hideWelcomeWithoutSaving());

        await axios.put(Boilerplate.route('api.nexus.group.update', { group: organizationId }), {
            welcomeModalShown: true,
        });
    };
};

export const completeRegistration = () => {
    return async (dispatch, getState) => {
        const { organizationId } = getState().app.admin;

        await axios.put(Boilerplate.route('api.nexus.group.update', { group: organizationId }), {
            registrationComplete: true,
        });

        axios.post(Boilerplate.route('post.registration.process'), {});

        dispatch(completeRegistrationWithoutSaving());
    };
};

export const addContact = data => {
    return async (dispatch, getState) => {
        let result = await axios.post(Boilerplate.route('api.nexus.pawn.store'), {
            ...adjustSettings(data),
            username: data.email,
            group: data.groupId,
            ...(data.role === 'orgAdmin' ? { trainingRequired: 1 } : {}),
        });

        // If username is already there we will add just new pawn role
        if (result.data.errors && result.data.errors.username) {
            result = await axios.post(Boilerplate.route('api.nexus.pawn.store'), {
                ...adjustSettings(data),
                user: data.email,
                group: data.groupId,
                ...(data.role === 'orgAdmin' ? { trainingRequired: 1 } : {}),
            });
        }

        if (result.data.errors) {
            throw result.data.errors;
        }

        await dispatch(loadInitialState(true));

        return getState().app.admin.contacts.find(item => item.email === data.email);
    };
};

export const addClubContactForUser = ({ userId, groupId, reload = true }) => {
    return async (dispatch, getState) => {
        const contactInfo = getState().app.admin.contacts.find(item => item.userId === userId);
        if (!contactInfo) {
            throw new Error(`There is no user with userId=${userId}`);
        }

        const result = await axios.post(Boilerplate.route('api.nexus.pawn.store'), {
            user: contactInfo.email,
            group: groupId,
            role: 'clubContact',
            primaryContact: true,
        });

        if (result.data.errors) {
            throw result.data.errors;
        }

        if (reload) {
            await dispatch(loadInitialState(true));
        }
    };
};

export const updateContact = ({ id, settings, reload = true }) => {
    return async (dispatch, getState) => {
        const contactInfo = getState().app.admin.contacts.find(item => item.id === id);
        if (!contactInfo || !contactInfo.pawnHash) {
            throw new Error(`There is no contact with pawnId=${id}`);
        }

        // Optimistic update
        dispatch(updateContactWithoutSaving({ id, settings }));

        await axios.put(Boilerplate.route('api.nexus.pawn.update', { pawn: id }), {
            ...adjustSettings(settings),
            pawnHash: contactInfo.pawnHash,
        });

        if (reload) {
            await dispatch(loadInitialState(true));
        }
    };
};

export const removeContact = id => {
    return async (dispatch, getState) => {
        const { pawnHash } = getState().app.admin.contacts.find(item => item.id === id);

        await axios.delete(Boilerplate.route('api.nexus.pawn.destroy', { pawn: id }) + `?pawnHash=${pawnHash}`);

        await dispatch(loadInitialState(true));
    };
};

export const setAddress = (address, timeout = addressChangeTimeout) => {
    return async (dispatch, getState) => {
        const { organizationId } = getState().app.admin;

        const update = async () => {
            await axios.put(
                Boilerplate.route('api.nexus.group.update', { group: organizationId }),
                adjustSettings(address)
            );
        };

        if (timeout === 0) {
            await update();
        } else {
            clearTimeout(addressChangeHandle);
            addressChangeHandle = setTimeout(update, timeout);
        }

        dispatch(setAddressWithoutSaving(address));
    };
};

// Reducer
export default handleActions(
    {
        [SET_INITIAL_STATE_FOR_ORG_ADMIN]: (state, action) => {
            const data = action.payload;

            const clubContacts = {};
            [...data.roles.clubContactList]
                .filter(user => user.fields.primaryContact)
                .forEach(user => {
                    const userId = user.user.userId;
                    const groupId = user.ancestorGroups.club.group.groupId;
                    if (groupId) {
                        clubContacts[groupId] = userId;
                    }
                });

            const result = {
                ...state,
                organizationId: data.groups.organization.group.groupId,
                organizationName: data.groups.organization.group.label,
                organizationAccessCode: data.groups.organization.fields.orgAccessCode,
                organizationShowGameOnStaffSurvey: data.groups.organization.fields.showGameOnStaffSurvey,
                registrationComplete: !!data.groups.organization.fields.registrationComplete,
                welcomeModalShown: !!data.groups.organization.fields.welcomeModalShown,
                clubs: data.groups.clubList.map(club => {
                    const selectedModules = [];
                    if (club.fields.riskBehaviorModuleSelected) {
                        selectedModules.push('riskBehavior');
                    }
                    if (club.fields.sedModuleSelected) {
                        selectedModules.push('sed');
                    }
                    if (club.fields.artsModuleSelected) {
                        selectedModules.push('arts');
                    }
                    if (club.fields.stemModuleSelected) {
                        selectedModules.push('stem');
                    }
                    if (club.fields.bullyingModuleSelected) {
                        selectedModules.push('bullying');
                    }
                    if (club.fields.collegeReadyModuleSelected) {
                        selectedModules.push('collegeReady');
                    }
                    if (club.fields.workforceReadyModuleSelected) {
                        selectedModules.push('workforceReady');
                    }

                    return {
                        groupId: club.group.groupId,
                        clubId: club.fields.clubId,
                        clubAccessId: club.fields.clubAccessId,
                        clubName: club.group.label,
                        surveyMethodLastYear: club.fields.surveyMethodLastYear,
                        participatingThisYear: club.fields.participatingThisYear,
                        surveyMethod: club.fields.surveyMethod,
                        paperEligible: club.fields.paperEligible,
                        consentForm: club.fields.consentForm,
                        trackingNumbers: club.fields.trackingNumbers ? JSON.parse(club.fields.trackingNumbers) : [],
                        maxOptionalModulesAllowed: club.fields.maxOptionalModulesAllowed,
                        contactUserId: clubContacts[club.group.groupId] || null,
                        paperOrderEnglishYouthLastYear: club.fields.paperOrderEnglishYouthLastYear,
                        paperOrderSpanishYouthLastYear: club.fields.paperOrderSpanishYouthLastYear,
                        paperOrderEnglishTeenLastYear: club.fields.paperOrderEnglishTeenLastYear,
                        paperOrderSpanishTeenLastYear: club.fields.paperOrderSpanishTeenLastYear,
                        paperReturnEnglishYouthLastYear: club.fields.paperReturnEnglishYouthLastYear,
                        paperReturnSpanishYouthLastYear: club.fields.paperReturnSpanishYouthLastYear,
                        paperReturnEnglishTeenLastYear: club.fields.paperReturnEnglishTeenLastYear,
                        paperReturnSpanishTeenLastYear: club.fields.paperReturnSpanishTeenLastYear,
                        paperOrderEnglishYouth: club.fields.paperOrderEnglishYouth,
                        paperOrderSpanishYouth: club.fields.paperOrderSpanishYouth,
                        paperOrderEnglishTeen: club.fields.paperOrderEnglishTeen,
                        paperOrderSpanishTeen: club.fields.paperOrderSpanishTeen,
                        maxYouth: club.fields.maxYouth,
                        maxTeen: club.fields.maxTeen,
                        modules: {
                            riskBehavior: {
                                allowed: !!club.fields.riskBehaviorModuleAllowed,
                                required: !!club.fields.riskBehaviorModuleRequired,
                                selectedLastYear: !!club.fields.riskBehaviorModuleSelectedLastYr,
                            },
                            sed: {
                                allowed: !!club.fields.sedModuleAllowed,
                                required: !!club.fields.sedModuleRequired,
                                selectedLastYear: !!club.fields.sedModuleSelectedLastYear,
                            },
                            arts: {
                                allowed: !!club.fields.artsModuleAllowed,
                                required: !!club.fields.artsModuleRequired,
                                selectedLastYear: !!club.fields.artsModuleSelectedLastYear,
                            },
                            stem: {
                                allowed: !!club.fields.stemModuleAllowed,
                                required: !!club.fields.stemModuleRequired,
                                selectedLastYear: !!club.fields.stemModuleSelectedLastYear,
                            },
                            bullying: {
                                allowed: !!club.fields.bullyingModuleAllowed,
                                required: !!club.fields.bullyingModuleRequired,
                                selectedLastYear: !!club.fields.bullyingModuleSelectedLastYear,
                            },
                            collegeReady: {
                                allowed: !!club.fields.collegeReadyModuleAllowed,
                                required: !!club.fields.collegeReadyModuleRequired,
                                selectedLastYear: !!club.fields.collegeReadyModuleSelectedLastYr,
                            },
                            workforceReady: {
                                allowed: !!club.fields.workforceReadyModuleAllowed,
                                required: !!club.fields.workforceReadyModuleRequired,
                                selectedLastYear: !!club.fields.workforceReadyModuleSelectedLast,
                            },
                        },
                        selectedModules,
                    };
                }),
                contacts: [
                    ...data.roles.orgAdminList.map(user => ({
                        id: user.pawn.pawnId,
                        userId: user.user.userId,
                        pawnHash: user.pawn.pawnHash,
                        firstName: user.user.firstName,
                        lastName: user.user.lastName,
                        email: user.user.email,
                        phone: user.user.phone,
                        positionTitle: user.fields.positionTitle,
                        trainingRequired: Boolean(user.fields.trainingRequired),
                        trainingComplete: Boolean(user.fields.trainingComplete),
                        consentTypeAffirmation: Boolean(user.fields.consentTypeAffirmation),
                        primaryContact: Boolean(user.fields.primaryContact),
                        role: 'orgAdmin',
                        invited: user.user.invitedDate,
                        groupId: user.ancestorGroups.club.group.groupId,
                    })),
                    ...data.roles.clubContactList.map(user => ({
                        id: user.pawn.pawnId,
                        userId: user.user.userId,
                        pawnHash: user.pawn.pawnHash,
                        firstName: user.user.firstName,
                        lastName: user.user.lastName,
                        email: user.user.email,
                        phone: user.user.phone,
                        positionTitle: user.fields.positionTitle,
                        trainingRequired: Boolean(user.fields.trainingRequired),
                        trainingComplete: Boolean(user.fields.trainingComplete),
                        consentTypeAffirmation: Boolean(user.fields.consentTypeAffirmation),
                        primaryContact: Boolean(user.fields.primaryContact),
                        role: 'clubContact',
                        invited: user.user.invitedDate,
                        groupId: user.ancestorGroups.club.group.groupId,
                    })),
                ],
                address: {
                    address1: data.groups.organization.fields.orgShippingAddress1,
                    address2: data.groups.organization.fields.orgShippingAddress2,
                    city: data.groups.organization.fields.orgShippingCity,
                    state: data.groups.organization.fields.orgShippingState,
                    zip: data.groups.organization.fields.orgShippingZipCode,
                },
            };

            // collapse online access by default for the orgAdmin who is the club contact as well
            const collapseOnlineAccess =
                new Set(result.contacts.filter(item => item.userId === state.actingUserId).map(item => item.role))
                    .size > 1;

            result.paneShowStatus = {
                onlineAccess: !collapseOnlineAccess,
                paperAccess: true,
            };

            return result;
        },
        [SET_INITIAL_STATE_FOR_CLUB_CONTACT]: (state, action) => {
            const data = action.payload;
            const orgData = data.roles.clubContactList[0].ancestorGroups.clubList[0].ancestorGroups.organization;

            return {
                ...state,
                organizationId: orgData.group.groupId,
                organizationName: orgData.group.label,
                registrationComplete: !!orgData.fields.registrationComplete,
                address: {
                    address1: orgData.fields.orgShippingAddress1,
                    address2: orgData.fields.orgShippingAddress2,
                    city: orgData.fields.orgShippingCity,
                    state: orgData.fields.orgShippingState,
                    zip: orgData.fields.orgShippingZipCode,
                },
                clubs: data.roles.clubContactList.map(item => {
                    const club = item.ancestorGroups.clubList[0];

                    const selectedModules = [];
                    if (club.fields.riskBehaviorModuleSelected) {
                        selectedModules.push('riskBehavior');
                    }
                    if (club.fields.sedModuleSelected) {
                        selectedModules.push('sed');
                    }
                    if (club.fields.artsModuleSelected) {
                        selectedModules.push('arts');
                    }
                    if (club.fields.stemModuleSelected) {
                        selectedModules.push('stem');
                    }
                    if (club.fields.bullyingModuleSelected) {
                        selectedModules.push('bullying');
                    }
                    if (club.fields.collegeReadyModuleSelected) {
                        selectedModules.push('collegeReady');
                    }
                    if (club.fields.workforceReadyModuleSelected) {
                        selectedModules.push('workforceReady');
                    }

                    return {
                        groupId: club.group.groupId,
                        clubId: club.fields.clubId,
                        clubAccessId: club.fields.clubAccessId,
                        clubName: club.group.label,
                        surveyMethodLastYear: club.fields.surveyMethodLastYear,
                        participatingThisYear: club.fields.participatingThisYear,
                        surveyMethod: club.fields.surveyMethod,
                        paperEligible: club.fields.paperEligible,
                        consentForm: club.fields.consentForm,
                        trackingNumbers: club.fields.trackingNumbers ? JSON.parse(club.fields.trackingNumbers) : [],
                        maxOptionalModulesAllowed: club.fields.maxOptionalModulesAllowed,
                        contactUserId: item.user.userId || null,
                        paperOrderEnglishYouthLastYear: club.fields.paperOrderEnglishYouthLastYear,
                        paperOrderSpanishYouthLastYear: club.fields.paperOrderSpanishYouthLastYear,
                        paperOrderEnglishTeenLastYear: club.fields.paperOrderEnglishTeenLastYear,
                        paperOrderSpanishTeenLastYear: club.fields.paperOrderSpanishTeenLastYear,
                        paperReturnEnglishYouthLastYear: club.fields.paperReturnEnglishYouthLastYear,
                        paperReturnSpanishYouthLastYear: club.fields.paperReturnSpanishYouthLastYear,
                        paperReturnEnglishTeenLastYear: club.fields.paperReturnEnglishTeenLastYear,
                        paperReturnSpanishTeenLastYear: club.fields.paperReturnSpanishTeenLastYear,
                        paperOrderEnglishYouth: club.fields.paperOrderEnglishYouth,
                        paperOrderSpanishYouth: club.fields.paperOrderSpanishYouth,
                        paperOrderEnglishTeen: club.fields.paperOrderEnglishTeen,
                        paperOrderSpanishTeen: club.fields.paperOrderSpanishTeen,
                        maxYouth: club.fields.maxYouth,
                        maxTeen: club.fields.maxTeen,
                        modules: {
                            riskBehavior: {
                                allowed: !!club.fields.riskBehaviorModuleAllowed,
                                required: !!club.fields.riskBehaviorModuleRequired,
                                selectedLastYear: !!club.fields.riskBehaviorModuleSelectedLastYr,
                            },
                            sed: {
                                allowed: !!club.fields.sedModuleAllowed,
                                required: !!club.fields.sedModuleRequired,
                                selectedLastYear: !!club.fields.sedModuleSelectedLastYear,
                            },
                            arts: {
                                allowed: !!club.fields.artsModuleAllowed,
                                required: !!club.fields.artsModuleRequired,
                                selectedLastYear: !!club.fields.artsModuleSelectedLastYear,
                            },
                            stem: {
                                allowed: !!club.fields.stemModuleAllowed,
                                required: !!club.fields.stemModuleRequired,
                                selectedLastYear: !!club.fields.stemModuleSelectedLastYear,
                            },
                            bullying: {
                                allowed: !!club.fields.bullyingModuleAllowed,
                                required: !!club.fields.bullyingModuleRequired,
                                selectedLastYear: !!club.fields.bullyingModuleSelectedLastYear,
                            },
                            collegeReady: {
                                allowed: !!club.fields.collegeReadyModuleAllowed,
                                required: !!club.fields.collegeReadyModuleRequired,
                                selectedLastYear: !!club.fields.collegeReadyModuleSelectedLastYr,
                            },
                            workforceReady: {
                                allowed: !!club.fields.workforceReadyModuleAllowed,
                                required: !!club.fields.workforceReadyModuleRequired,
                                selectedLastYear: !!club.fields.workforceReadyModuleSelectedLast,
                            },
                        },
                        selectedModules,
                    };
                }),
                contacts: data.roles.clubContactList.map(user => ({
                    id: user.pawn.pawnId,
                    userId: user.user.userId,
                    pawnHash: user.pawn.pawnHash,
                    firstName: user.user.firstName,
                    lastName: user.user.lastName,
                    email: user.user.email,
                    phone: user.user.phone,
                    positionTitle: user.fields.positionTitle,
                    trainingRequired: Boolean(user.fields.trainingRequired),
                    trainingComplete: Boolean(user.fields.trainingComplete),
                    consentTypeAffirmation: Boolean(user.fields.consentTypeAffirmation),
                    primaryContact: Boolean(user.fields.primaryContact),
                    role: 'clubContact',
                    invited: user.user.invitedDate,
                    groupId: user.ancestorGroups.clubList[0].group.groupId,
                })),
            };
        },
        [SET_ACTIVE_ITEM]: (state, action) => {
            const newActiveItem = action.payload;
            if (state.activeItem === newActiveItem || !state.navItems.find(item => item.code === newActiveItem)) {
                return state;
            }

            return {
                ...state,
                activeItem: newActiveItem,
            };
        },
        [CHANGE_CLUB_SETTINGS]: (state, action) => {
            const { groupId, settings } = action.payload;

            return {
                ...state,
                clubs: state.clubs.map(club => {
                    if (club.groupId !== groupId) {
                        return club;
                    }

                    return {
                        ...club,
                        ..._pick(settings, [
                            'participatingThisYear',
                            'surveyMethod',
                            'consentForm',
                            'selectedModules',
                            'paperOrderEnglishYouth',
                            'paperOrderSpanishYouth',
                            'paperOrderEnglishTeen',
                            'paperOrderSpanishTeen',
                        ]),
                    };
                }),
            };
        },
        [SET_ADDRESS]: (state, action) => ({
            ...state,
            address: {
                ...state.address,
                ..._pick(action.payload, ['address1', 'address2', 'city', 'state', 'zip']),
            },
        }),
        [UPDATE_CONTACT]: (state, action) => {
            const { id, settings } = action.payload;

            return {
                ...state,
                contacts: state.contacts.map(item => {
                    if (item.id !== id) {
                        if (!settings.primaryContact) {
                            return item;
                        }

                        return { ...item, primaryContact: false };
                    }

                    return {
                        ...item,
                        ..._pick(settings, [
                            'firstName',
                            'lastName',
                            'email',
                            'phone',
                            'positionTitle',
                            'primaryContact',
                            'trainingRequired',
                            'trainingComplete',
                            'consentTypeAffirmation',
                        ]),
                    };
                }),
            };
        },
        [TOGGLE_PANE]: (state, action) => ({
            ...state,
            paneShowStatus: {
                ...state.paneShowStatus,
                [action.payload]: !state.paneShowStatus[action.payload],
            },
        }),
        [COMPLETE_REGISTRATION]: (state, action) => ({
            ...state,
            registrationComplete: true,
        }),
        [HIDE_WELCOME]: (state, action) => ({
            ...state,
            welcomeModalShown: true,
        }),
        [SET_MEMBER_STAT]: (state, action) => {
            const memberStat = {};
            const clubs = action.payload.clubs || [];

            clubs.forEach(club => {
                memberStat[club.clubId] = {
                    started: +club.startedCount,
                    inClubPartial: +club.inClubPartialCount,
                    inClubCompletedYouth: +club.inClubYouthCompletedCount,
                    inClubCompletedTeen: +club.inClubTeenCompletedCount,
                    virtualPartial: +club.virtualPartialCount,
                    virtualCompletedYouth: +club.virtualYouthCompletedCount,
                    virtualCompletedTeen: +club.virtualTeenCompletedCount,
                    youthResponsesCollectedLastYear: +club.youthResponsesCollectedLastYear,
                    teenResponsesCollectedLastYear: +club.teenResponsesCollectedLastYear,
                    staffComplete: +club.staffComplete,
                };
            });

            return {
                ...state,
                memberStat,
                orgStaffSurveyCount: action.payload.orgStaffSurveyCount || 0,
            };
        },
    },
    initialState
);
